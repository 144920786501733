<i18n locale="th" lang="yaml" >
select.company.placeholder.default : "เลือกศูนย์บริการ"
</i18n>

<template>
	<a-select
		class="myselect-companies"
		:get-popup-container="getPopupContainer"
		:placeholder="displayCompanyPlaceholder"
		:value="value"
		:allow-clear="allowClear"
		:disabled="disabled"
		@change="handleChange">
		<a-select-option v-for="company in listCompanies" :key="company.id" :value="company.id">
			{{company.name}}
		</a-select-option>
	</a-select>
</template>

<script>
import {Select} from "ant-design-vue"
import {mapGetters, mapState} from "vuex"
import {isStringEmpty} from "@utils/stringUtil"
import {COMPANY_STATUS} from "@utils/companyUtil"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-select" : Select,
		"a-select-option" : Select.Option,
	} ,
	mixins : [PopupMixin] ,
	model : {
		prop : 'value',
		event : 'change',
	} ,
	props : {
		value : {
			type : null,
			default : undefined
		} ,
		placeholder : {
			type : String,
			default : null
		} ,
		disabled : {
			type : Boolean,
			default : false,
		} ,
		allowClear : {
			type : Boolean,
			default : false,
		} ,
		activeOnly : {
			type : Boolean,
			default : true,
		} ,
		excludeMine : {
			type : Boolean ,
			default : false
		}
	} ,
	computed : {
		...mapState('user',['companies']) ,
		...mapState('auth',['currentCompany']) ,
		...mapGetters('user',['getCompanyById','listActiveOnly']) ,
		displayCompanyPlaceholder() {
			return isStringEmpty(this.placeholder) ? this.$t('select.company.placeholder.default') : this.placeholder
		} ,
		listCompanies() {
			return this.companies.filter((c) => {
				if (this.activeOnly && c.company_status !== COMPANY_STATUS.ACTIVE)
					return false
				if (this.excludeMine && c.id === this.currentCompany.id)
					return false
				return true
			})
		}
	} ,
	methods : {
		handleChange(value) {
			this.$emit('change',value);
			this.$emit('changeCompany',this.getCompanyById(value))
		}
	}
}
</script>

<style lang="less" scoped>
.myselect-companies {
	width : 200px;
}
</style>
