<i18n locale="th" lang="yaml" src="@i18n/service/payment.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/return.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >

onfield_return.page.title : "ใบส่งมอบโดรน"
onfield_return.page.description : "ขั้นตอนที่ 8/8"
onfield_return.form.success: "บันทึกใบส่งมอบโดรนสำเร็จ"

onfield_return.payment : "สถานะการชำระเงิน"
onfield_return.detail : "การรับเครื่องคืน"

onfield_return.payment.field.completed_date.display : "ชำระครบเมื่อวันที่ {date}"

onfield_return.field.remark.placeholder : "หมายเหตุเพิ่มเติมของการส่งคืน เช่น ลูกค้าติดต่อมาให้ช่วยนำส่งคืนให้หน่อย โดยมีการคิดค่าใช้จ่ายในการส่งเพิ่มเติม"
onfield_return.field.return_by_id.help : "คนที่ทำเรื่องเอาเครื่องออกจากศูนย์"
onfield_return.field.return_date.help : "เวลาที่เครื่องนี้ออกจากศูนย์ โดยประมาณ เช่น เบิกออกเพื่อส่งต่อ"

onfield_return.refer.field.remark.placeholder : "เช่น ส่งไป HGR เนื่องจากไม่สามารถตรวจหาสาเหตุได้"

onfield_return.out.detail : "รายละเอียดการส่งเพิ่มเติม"

onfield_return.out.self_shipping.shipping_by.label : "ส่งโดย"
onfield_return.out.self_shipping.receive_date.label : "ถึงลูกค้าวันที่"

onfield_return.files : "ข้อมูลอื่น ๆ ประกอบการส่งคืน"
onfield_return.images.label : "รูป/วีดีโอ ประกอบการส่งมอบ"
onfield_return.others.label : "ไฟล์ประกอบอื่น ๆ เช่น Log Files"
onfield_return.document_images.label : "รูปใบส่งมอบเครื่องพร้อมลายเซ็นต์"
onfield_return.document_files.label : "ไฟล์ใบส่งมอบเครื่องพร้อมลายเซ็นต์"

onfield_return.confirm.title : "กรุณายืนยันเพื่อดำเนินการต่อไป"
onfield_return.confirm.message : "ต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่?"
onfield_return.success : "ดำเนินการเรียบร้อย"

action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
action.ask_leave.message : "คุณได้แก้ไขข้อมูลการตรวจรับ และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"
action.create.return_form: "สร้างใบส่งมอบโดรน"
action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
action.update.no_form : "กรุณาสร้างใบส่งมอบโดรน และบันทึกให้เรียบร้อยก่อนดำเนินการต่อ"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			class="main-header"
			:title="$t('onfield_return.page.title')"
			:description="$t('onfield_return.page.description')"/>

		<div class="page-padding background">
			<ErrorMessagePane :error="saveError" />
			<div v-show="!loading" class="page-padding">
				<template>
					<div class="mytab-section-title">
						{{$t('onfield_return.payment')}}
					</div>
					<div class="return-subsection">
						<div class="return-row">
							<label>{{$t('service_payment.field.total_fee')}} : </label>
							<span class="return-value">{{payment.total_fee | price}}</span>
						</div>
						<div class="return-row">
							<label>{{$t('service_payment.field.status')}} : </label>
							<div class="return-value">
								<a-select
									class="myselect-users"
									:value="payment.status"
									:options="paymentStatusOptions"
									@change="handlePaymentStatusChange">
								</a-select>
							</div>
							<div v-if="$notEmpty(payment.completed_date)" class="help">
								{{$t('onfield_return.payment.field.completed_date.display',{ date: $dayjs(payment.completed_date).format("LL") })}}
							</div>
						</div>
						<div v-if="$notEmpty(payment.payment_remark)" class="return-row">
							<label>{{$t('service_payment.field.payment_remark')}} : </label>
							<div v-trim class="return-value multiline" style="max-width:800px">
								{{payment.payment_remark}}
							</div>
						</div>
					</div>
				</template>

				<div class="mytab-section-title">
					{{$t('onfield_return.detail')}}
				</div>
				<div class="return-subsection">
					<div class="return-row">
						<label>{{$t('service_return.field.return_by_id')}}* : </label>
						<template>
							<div class="return-value">
								{{currentUser.username}}
							</div>
							<div class="help">
								{{$t('onfield_return.field.return_by_id.help')}}
							</div>
						</template>
					</div>
					<div class="return-row">
						<label>{{$t('service_return.field.return_type')}}* : </label>
						<template>
							<div v-if="returnValue.return_type == 'refer'" class="return-value">
								{{$t('enum.service.return.type.refer')}}
							</div>
							<div v-else class="return-value">
								{{$t('enum.service.return.type.customer')}}
							</div>
						</template>
					</div>
					<template v-if="returnValue.return_type == 'refer'">
						<div class="divider" />
						<div class="return-row">
							<label>{{$t('service_refer.field.to_company_id')}}* : </label>
							<div class="return-input">
								<CompanySelect
									v-model="referValue.to_company_id"
									allow-clear/>
							</div>
						</div>
						<div class="return-row">
							<label class="valign-top">{{$t('service_refer.field.remark')}} : </label>
							<a-textarea
								v-model="referValue.remark"
								style="max-width:500px;"
								:auto-size="{ minRows: 4,maxRows: 10 }"
								:placeholder="$t('onfield_return.refer.field.remark.placeholder')"/>
						</div>
					</template>

					<div class="return-row">
						<label class="valign-top">{{$t('service_return.field.return_date')}}* : </label>
						<template>
							<div class="return-value">
								{{displayReturnDate}}
								<div>
									{{$tenum('time_range', returnValue.return_time_range)}}
								</div>
							</div>

							<div class="help">
								{{$t('onfield_return.field.return_date.help')}}
							</div>
						</template>
					</div>
					<div class="return-row">
						<label class="valign-top">{{$t('service_return.field.delivery_type')}}* : </label>
						<div class="return-value">
							<div class="return-detail-title">
								{{$t('onfield_return.out.detail')}}
							</div>
							<div class="return-detail">
								<div class="return-col">
									<label>{{$t('onfield_return.out.self_shipping.shipping_by.label')}}</label>
									<div class="return-value">
										{{currentUser.full_name}}
									</div>
								</div>
								<div class="return-col">
									<label>{{$t('onfield_return.out.self_shipping.receive_date.label')}}</label>
									<div class="return-value">
										{{displayReturnDate}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="create-return-form">
						<a-button
							icon="form"
							type="info"
							@click="openModal">
							{{$t('action.create.return_form')}}
						</a-button>
					</div>
					<div class="return-row full">
						<label>{{$t('service_return.field.remark')}}</label>
						<a-textarea
							v-model="returnValue.remark"
							style="max-width:800px;"
							:auto-size="{ minRows: 4,maxRows: 10 }"
							:placeholder="$t('onfield_return.field.remark.placeholder')"/>
					</div>
					<template>
						<div class="mytab-section-title">
							{{$t('onfield_return.files')}}
						</div>
						<div class="return-subsection files-upload">
							<div class="files-component">
								<ServiceFilesPanel
									ref="serviceFilesRef"
									:error="componentError"
									:service="service"
									:label="$t('onfield_return.images.label')"
									:others-label="$t('onfield_return.others.label')"
									:can-update="true"/>
							</div>
							<div class="files-component">
								<ServiceFilesPanel
									ref="serviceDocumentFilesRef"
									:error="componentError"
									:service="service"
									:label="$t('onfield_return.document_images.label')"
									:others-label="$t('onfield_return.document_files.label')"
									:can-update="true"/>
							</div>
						</div>
					</template>
				</div>

				<div class="page-action-right">
					<a-button
						type="primary"
						icon="right-circle"
						class="right-button"
						@click="handleSave">
						{{$t('action.next.page')}}
					</a-button>
				</div>
				<template>
					<div>
						<ServiceOnfieldReturnForm
							ref="returnFormRef"
							:payment="payment"
							:return-by-user-data="returnByUserData"
							:return-value="returnValue"
							@save="postReturnDroneDoc"/>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import ServiceObjectMixin from '@mixins/service/ServiceObjectMixin.vue'
import axios from 'axios'
import _isEqual from 'lodash/isEqual'
import ModelError from '@utils/errors/ModelError'
import ServiceFilesPanel from "@components/service/ServiceFilesPanel.vue"
import ServiceOnfieldReturnForm from '@components/service/ServiceOnfieldReturnForm.vue'
import {scrollTopContent,getEnumSelectOptions} from "@utils/formUtil"
import {timeRange} from "@utils/stringUtil"
import {replaceDeepProperty,copyDeep,transformToJsonProp} from "@utils/objectUtil"
import FormError from '@utils/errors/FormError'
import dayjs from "dayjs"
import {mapGetters, mapState} from "vuex"
import ApiError from '@utils/errors/ApiError'
import ErrorMessagePane from '@components/common/ErrorMessagePane.vue'
import CompanySelect from "@components/company/CompanySelect.vue"

function _defaultReturn() {
	return {
		return_type : undefined,
		delivery_method : undefined,
		return_date : undefined,
		return_time_range : undefined,
		return_by_id : undefined,
		remark : undefined,
	}
}
function _defaultRefer() {
	return {
		to_company_id : undefined ,
		remark : undefined,
	}
}

export default {
	components: {
		ServiceOnfieldReturnForm,
		ServiceFilesPanel,
		ErrorMessagePane,
		CompanySelect,

	},
	mixins: [PageMixin, ServiceObjectMixin],
	props: {
		service: {
			type: null,
			default: () => [],
		},
	},
	data() {
		return {
			loading : false,
			payment : {} ,
			serviceReturn : {} ,
			serviceRefer : {} ,
			serviceRepair: {},
			serviceFiles: [],
			serviceDocumentFiles: [],

			returnValue : _defaultReturn() ,
			referValue : _defaultRefer() ,

			ignorePreventExit : false,
			oldFormData : {} ,
			saveError : undefined,

			displayReturnDate: null,
			deliveryFormFileKey: '',
		}
	},
	computed: {
		...mapGetters('user',['getCompanyById', 'getUserDataById']) ,
		...mapState('auth',['currentUser','currentCompany']),
		canUpdateReturnDetail() {
			return this.serviceRefer.id > 0	&&
				!this.serviceRefer.refer_service_id
		} ,
		paymentStatusOptions() {
			const options = ['new','in_progress','complete']
			return getEnumSelectOptions(this,'service.payment.status',options)
		} ,
		returnTypeOptions() {
			const options = ['customer','refer']
			return getEnumSelectOptions(this,'service.return.type',options)
		} ,
		returnByUserData() {
			return this.currentUser
		} ,
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		} ,
	},
	watch : {
		$route(newVal) {
			if (!this.payment || newVal.params.id != this.payment.id) {
				this.fetchData()
			}
		}
	} ,
	mounted() {
		this.fetchData();
	} ,
	beforeMount() {
		window.addEventListener('beforeunload', this.preventExit)
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.preventExit)
	},
	methods : {
		handlePaymentStatusChange(status) {
			this.payment.status = status
		},
		getCompanyName(companyId) {
			return this.getCompanyById(companyId).name
		},
		openModal() {
			this.$refs.returnFormRef.openModal({
				serviceId: this.service.id,
				isDeviceOnly: this.service.is_device_only,
				serviceNumber: this.service.service_no,
				droneSerial: this.service.droneData.drone.serial_no,
				dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
				droneModelName: this.service.droneData.model.name,
				customerName: this.service.customer_name,
				customerPhone: this.service.customer_phone,
				customerProvince: this.service.customer_province,
				referCompany: this.getCompanyName(this.referValue.to_company_id),
				openDate: this.service.open_date,
				openBy: this.service.openByData.user.full_name,
				createdDateTime: this.service.created_datetime
			})
		},
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.loading = true
			axios.get("/api/services/"+serviceId+"/return")
				.then((response) => {
					this.payment = response.data.data.payment
					this.serviceFiles = response.data.data.service_files
					this.serviceDocumentFiles = response.data.data.service_document_files
					this.serviceReturn = response.data.data.return
					this.serviceRefer = response.data.data.refer
					return axios.get("/api/services/"+serviceId+"/jobs")
				})
				.then((response) => {
					this.serviceRepair = response.data.data.job_repair
					this.initReturn()
				})
				.catch((error) => {
					this.fetchError(error)
				})
				.finally(() => {
					this.loading = false
				})
		} ,
		initReturn() {
			const date = new Date()
			this.saveError = undefined
			this.returnValue = _defaultReturn()
			this.referValue = _defaultRefer()
			this.returnValue.return_date = this.$dayjs(date).format('YYYY-MM-DD').toString()
			this.displayReturnDate = this.$dayjs(date).locale('th').format('DD MMMM YYYY')
			this.returnValue.return_time_range = timeRange(date.getHours())
			this.returnValue.delivery_method = 'self_shipping'
			if (this.serviceReturn) {
				replaceDeepProperty(this.returnValue,this.serviceReturn)
			}
			if (this.serviceRefer) {
				replaceDeepProperty(this.referValue,this.serviceRefer)
			}
			if (this.serviceRepair.repair_result === 'success') {
				this.returnValue.return_type = 'customer'
			} else {
				this.returnValue.return_type = 'refer'
				this.referValue = {
					id: this.$route.params.id,
					remark: this.referValue.remark,
					updatedByData: {
							user: this.getUserDataById(this.currentUser.id).user,
							company: this.getCompanyById(this.currentCompany.id)
					},
					toCompanyData: null,
					accepted_datetime: null,
					updated_datetime: new Date(),
					to_company_id: this.referValue.to_company_id,
					refer_service_id: null,
					accepted_by_id: null,
					updated_by_id: this.currentUser.id
				}
			}
			this.$nextTick(() => {
				if (this.$refs.rtnDeliveryDetailRef) {
					this.$refs.rtnDeliveryDetailRef.setData(this.returnValue.delivery_method,this.serviceReturn.deliveryData)
				}
				this.oldFormData = this.dumpFormData()
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.serviceDocumentFilesRef) {
					this.$refs.serviceDocumentFilesRef.setData(this.serviceDocumentFiles)
				}
			})
		} ,
		validateFormData(formData) {
			this.saveError = undefined
			const formError = new FormError(this)

			if (this.returnValue.return_type == 'refer') {
				if (this.referValue.to_company_id === undefined || this.referValue.to_company_id <= 0) {
					formError.addMessage(
						'refer',
						this.$t("validate.required",{field : this.$t('service_refer.field.to_company_id')}),
						this.$t('service_refer.name')
					)
				}
			}

			if (formError.hasErrors()) {
				this.saveError = formError
				this.$nextTick(()=>{
					scrollTopContent()
				})
				return false
			} else {
				return true
			}
		} ,
		dumpDroneReturnData(data) {
			return {
				module: 'service',
				renderData: {
					printDate: new Date().toLocaleDateString() + ': ' + new Date().toLocaleTimeString(),
					droneReceiveDateTime: data.droneReceiveDateTime,
					serviceNumber: this.service.service_no,
					droneSerial: this.service.droneData.drone.serial_no,
					dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
					droneModelName: this.service.droneData.model.name,
					customerName: this.service.customer_name,
					customerPhone: this.service.customer_phone,
					customerProvince: this.service.customer_province,
					reporter: this.service.openByData.user.full_name,
					devices: data.devices.map((device) => this.$tenum('service.pre_service.devices', device)),
					others: data.others,
					batteries: data.batteries,
					isDeviceOnly: this.isDeviceOnly,
					signData : data.signData,
					companyData: this.getCompanyById(this.currentCompany.id),
					senderCompany: this.currentCompany.name,
					receiverCompany: data.receiverCompany,
					accepted: data.accepted,
					remark: this.returnValue.remark,
					totalPrice: this.payment.total_fee,
					status: this.$tenum('service.payment.status', this.payment.status),
					owner: this.currentUser.full_name,
					receiver: this.service.customer_name,
					deliveryDate: this.returnValue.return_date,
					deliveryType: this.$tenum('delivery.type.out', this.returnValue.delivery_method)
				}
			}
		},
		postReturnDroneDoc(data) {
			axios.post(`/api/services/${this.service.id}/onfield-delivery-form-upload`, this.dumpDroneReturnData(data))
				.then((res) => {
					this.modalLoading = true
					const uid = Math.floor(Math.random() * res.data.file.file_size)
					const customFile = {
						uid: uid,
						name: res.data.file.filename,
						file_key: res.data.file.file_key,
						size:  res.data.file.file_size,
						type: res.data.file.type,
						status: "done",
					}
					this.$refs.serviceDocumentFilesRef.$refs.fileUploader.addCustomFile(customFile)
					this.$message.success(this.$t('onfield_return.form.success'))
					this.$refs.returnFormRef.handleClose()
					this.deliveryFormFileKey = res.data.file.file_key
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this,error));
				})
				.finally(() => {
					this.modalLoading = false
				});
		},
		dumpFormData() {
			const returnForm = copyDeep(this.returnValue)
			returnForm.deliveryData = {
				shipping_by: this.currentUser.full_name,
				receive_date: this.returnValue.return_date
			}
			this.returnValue.return_by_id = this.currentUser.id

			const formData = {
				return : returnForm,
				service_files: [],
			}
			if (this.canUpdateReturnDetail && returnForm.return_type == 'refer') {
				const referForm  = copyDeep(this.referValue)
				formData.refer = referForm
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({upload_type: 'delivery', files: this.$refs.serviceFilesRef.getData()})
			}
			if (this.$refs.serviceDocumentFilesRef) {
				formData.service_files.push({upload_type: 'delivery-doc', files: this.$refs.serviceDocumentFilesRef.getData()})
			}
			return formData
		},
		handleSave() {
			const formData = this.dumpFormData()

			this.payment.completed_date = dayjs().format('YYYY-MM-DD')
			const paymentForm = {
				payment: this.payment
			}
			const docForm = formData.service_files[1].files.find(file => file.file_key === this.deliveryFormFileKey)
			if (!docForm) {
				this.$message.error(this.$t('action.update.no_form'))
				return
			}
			if (!this.isDataChange(formData)) {
				this.$message.info(this.$t('action.update.no_change'))
				return
			}
			if (!this.validateFormData(formData)) {
				return
			}

			this.$confirm({
				title : this.$t('onfield_return.confirm.title') ,
				content : this.$t('onfield_return.confirm.message') ,
				okText : this.$t('common.ok') ,
				cancelText: this.$t('common.cancel'),
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)
					transformToJsonProp(formData.return,'deliveryData','delivery_json')

					axios.put(`/api/services/${this.service.id}/return`,formData)
						.then((res)=>{
							return axios.put(`/api/services/${this.service.id}/payment`,paymentForm)
						})
						.then((res)=>{
							return axios.post(`/api/services/${this.service.id}/update-onfield-state`, { state: 'done' })
						})
						.then((res)=>{
							return axios.post(`/api/services/${this.service.id}/update-status/close`)
						})
						.then((res)=>{
							this.ignorePreventExit = true
							this.$message.success(this.$t('onfield_return.success'))
							this.$router.push({ name: 'onfield/close', params:{id:this.service.id} })
						})
						.catch((error) => {
							this.saveError = error
						})
						.finally(()=>{
							this.hidePageLoading()
						})
				} ,
			})
		} ,
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		}
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('action.ask_leave.title') ,
				content : this.$t('action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.create-return-form {
	margin: 0 0 10px 0;
}
.myselect-users {
	width : 250px;
}
.return-subsection {
	margin-left : 64px;
	margin-bottom : 32px;
	.mobile & {
		margin-left : 16px;
	}
	.divider {
		border-bottom : 1px dashed @border-color-base;
		height : 1px;
		max-width: 800px;
		margin : 20px 0;
	}
}
.return-row {
	margin-bottom : 12px;
	.mobile {
		margin-bottom : 24px;
	}
	> label {
		color : @info-color;
		width : 150px;
		text-align: right;
		margin-right : 8px;
		display : inline-block;
		.mobile & {
			display : block;
			text-align: left;
			margin-bottom : 2px;
			width : auto;
		}
	}
	&.full > label {
		text-align : left;
		display : block;
		margin-bottom : 4px;
	}
	.return-value {
		display : inline-block;
		color : @primary-color;
	}
	.return-input {
		display : inline-block;
		.mobile & {
			display : block;
		}
	}
	.return-time-range {
		display : block;
		margin-top : 8px;
		margin-bottom : 4px;
	}
	.help {
		color : @text-muted;
		margin-top : 2px;
		font-size : 0.95em;
		margin-left : 158px;
		.mobile & {
			margin-left : 0;
		}
	}
}
.main-header {
	text-align: center;
}
.background {
	background : white;

	.mobile & {
		padding : 12px 16px;
	}
}
.right-button {
	margin: 0 0.2rem 0 auto;
}
.return-detail-title {
	text-decoration: underline;
	color : @primary-color;
	margin-bottom : 4px;
	width : 100%;
}
.return-detail {
	margin-top : 4px;
	display : flex;
	flex-wrap: wrap;
	.return-col {
		margin : 0 16px 8px 0;
	}
	label {
		color : @info-color;
		margin-right : 2px;
		&::after {
			content : ' :'
		}
	}
}
.files-component {
	margin-right: 2rem;
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
.page-padding {
	padding : 16px 16px 20px;
	.mobile & , .tablet & {
		padding : 8px 8px 24px;
	}
}
</style>
